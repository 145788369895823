<template>
  <v-dialog
    v-model="show"
    v-bind="$attrs"
    max-width="510px"
    persistent
    overlay-color="overlayColor"
  >
    <v-card class="cardBg py-18 px-20 pt-sm-23 pr-sm-35 pb-sm-35 pl-sm-0">
      <v-row no-gutters>
        <v-col cols="12" sm="6" class="pb-8 pr-11">
          <div class="font-large-title text-uppercase dialogTitle--text text-sm-right">
            Add routing plan
          </div>
        </v-col>
      </v-row>
      
      <v-form class="pb-20" ref="form" v-model="validForm">
        <v-row no-gutters>
          <v-col class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10" cols="12" sm="6">
            <span class="font-normal nameField--text text-sm-right">Routing plan</span>
            <portal-target
              class="field-message font-normal error--text text-sm-right"
              name="changeRoutingPlan"
            ></portal-target>
          </v-col>
          
          <v-col class="pb-8 pb-sm-10" cols="12" sm="6">
            <AutocompleteField
              v-model="changedRoutingPlan"
              :items="routingPlans"
              item-text="name"
              item-value="slug"
              :rules="rules"
              :loading="loadingRoutingPlans"
              clearable
              checkBg
            >
              <template #message="{ key, message }">
                <portal to="changeRoutingPlan" v-if="!!message">
                  {{ message }}
                </portal>
              </template>
            </AutocompleteField>
          </v-col>
        </v-row>
      </v-form>
      <div class="d-flex justify-sm-end">
        <CancelButton @click="closeDialog">Cancel</CancelButton>
        
        <ActionButton class="ml-5 ml-sm-11" @click="submitForm">Save</ActionButton>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AutocompleteField from '@/components/select/AutocompleteField'
import CancelButton from '@/components/buttons/CancelButton'
import ActionButton from '@/components/buttons/ActionButton'

import { getAllRoutingPlans } from '@/api/routing-plan-routes'

export default {
  name: 'DialogChangeRoutingPlan',
  inheritAttrs: false,
  components: { AutocompleteField, CancelButton, ActionButton },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    currentRoutingPlan: {
      type: String | Number,
      default: null
    }
  },
  data: () => ({
    validForm: true,
    loadingRoutingPlans: false,
    changedRoutingPlan: null,
    routingPlans: [],
    rules: [],
  }),
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  mounted() {
    this.loadAllRoutingPlans()
  },
  methods: {
    async loadAllRoutingPlans() {
      this.loadingRoutingPlans = true
      
      const { success, payload, message } = await getAllRoutingPlans()
      
      if (success) {
        this.routingPlans = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingRoutingPlans = false
    },
    async submitForm() {
      this.$emit('updateRoutingPlan', this.changedRoutingPlan)
    },
    closeDialog() {
      this.show = false
    }
  },
  watch: {
    currentRoutingPlan: {
      handler(plan) {
        this.changedRoutingPlan = plan
      }
    }
  },
}
</script>
