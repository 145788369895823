<template>
  <v-slider
    v-model="fieldValue"
    inverse-label
    hide-details
    thumb-color="primary"
    thumb-label="always"
    v-bind="$attrs"
    v-on="$listeners"
  ></v-slider>
</template>

<script>
export default {
  name: 'PrimarySlider',
  inheritAttrs: false,
  props: {
    value: {
      type: Number,
      default: 1
    },
  },
  computed: {
    fieldValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
}
</script>