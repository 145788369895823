<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="header-block d-flex align-center justify-space-between pb-8 pb-sm-15">
      <div class="d-flex align-center">
        <portal to="pageTitle" :disabled="checkMd">
          <div class="font-page-header pr-5 pr-sm-15">ivr</div>
        </portal>
        
        <ActionButton @click="closeAction">Manage ivr</ActionButton>
      </div>
      
      <div>
        <CancelButton @click="closeAction">Close</CancelButton>
      </div>
    </div>
    <v-card
      class="create-card cardBg py-15 px-12 rounded-20"
      :class="checkSm ? 'py-sp-23 px-sp-20' : 'py-sm-25 px-sm-25'"
      flat
      outlined
    >
      <div class="setup-content">
        <v-row class="d-block d-md-flex align-center" no-gutters>
          <v-col cols="12" md="4" lg="2" class="pt-0 pt-md-26">
            <TextDescription :description="description[0]" />
          </v-col>
          <v-col cols="12" md="8" lg="10">
            <v-form ref="form" v-model="validateForm">
              <v-row no-gutters>
                <v-col
                  class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="5" md="4" lg="3"
                >
                  <span class="font-normal nameField--text">IVR name</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="buyerName"
                  ></portal-target>
                </v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <FormField
                    v-model="formData.name"
                    :rules="rules"
                    :loading="loadingDetail"
                    class="field-container"
                  >
                    <template #message="{ key, message }">
                      <portal to="buyerName" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </FormField>
                </v-col>
  
                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                  cols="12" sm="5" md="4" lg="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Time limit</span>
                  <portal-target
                    class="field-message font-normal error--text text-sm-right"
                    name="timeLimitIvr"
                  ></portal-target>
                </v-col>
  
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <InputNumberControl
                    v-model="formData['time_limit']"
                    class="field-container"
                    :rules="rules"
                  >
                    <template #message="{ key, message }">
                      <portal to="timeLimitIvr" v-if="!!message">
                        {{ message }}
                      </portal>
                    </template>
                  </InputNumberControl>
                </v-col>

                <v-col
                  class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10 pt-10"
                  cols="12" sm="5" md="4" lg="3"
                >
                  <span class="font-normal nameField--text text-sm-right">Tries</span>
                </v-col>

                <v-col class="pb-10 pt-10" cols="12" sm="7" md="8" lg="9">
                  <PrimarySlider
                    v-model="formData.tries"
                    class="field-container"
                    :thumb-size="26"
                    :min="1"
                    :max="10"
                  />
                </v-col>
  
                <v-col class="pr-sm-11 pb-4 pb-sm-10" cols="12" sm="5" md="4" lg="3"></v-col>
                <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                  <ToggleButton
                    v-model="sayOption"
                    :buttons="sayOptions"
                  />
                </v-col>
  
                <v-expand-transition>
                  <v-col cols="12" v-if="!sayOption">
                    <v-row no-gutters>
                      <v-col
                        class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                        cols="12" sm="5" md="4" lg="3"
                      >
                        <span class="font-normal nameField--text">Text to speak</span>
                        <portal-target
                          class="field-message font-normal error--text text-sm-right"
                          name="textToSpeak"
                        ></portal-target>
                      </v-col>
                      <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                        <FormTextarea
                          v-model="formDataSay.description"
                          :loading="loadingDetail"
                          class="field-container"
                          :rules="rules"
                        >
                          <template #message="{ key, message }">
                            <portal to="textToSpeak" v-if="!!message">
                              {{ message }}
                            </portal>
                          </template>
                        </FormTextarea>
                      </v-col>
  
                      <v-col
                        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                        cols="12" sm="5" md="4" lg="3"
                      >
                        <span class="font-normal nameField--text text-sm-right">Language</span>
                        <portal-target
                          class="field-message font-normal error--text text-sm-right"
                          name="languageField"
                        ></portal-target>
                      </v-col>
  
                      <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                        <AutocompleteField
                          class="field-container"
                          v-model="formDataSay['language_id']"
                          :items="languages"
                          item-text="name"
                          item-value="id"
                          :rules="rules"
                          checkBg
                          @change="changeLanguage"
                        >
                          <template #message="{ key, message }">
                            <portal to="languageField" v-if="!!message">
                              {{ message }}
                            </portal>
                          </template>
                        </AutocompleteField>
                      </v-col>
  
                      <v-col
                        class="d-flex flex-column align-start align-sm-end justify-center pr-sm-11 pb-4 pb-sm-10"
                        cols="12" sm="5" md="4" lg="3"
                      >
                        <span class="font-normal nameField--text">Voice</span>
                        <portal-target
                          class="field-message font-normal error--text text-sm-right"
                          name="voiceField"
                        ></portal-target>
                      </v-col>
  
                      <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                        <SelectField
                          v-model="formDataSay.voice"
                          class="field-container"
                          :items="voices"
                          item-text="name"
                          item-value="id"
                          :loading="loadingDetail"
                          :rules="rules"
                          checkBg
                        >
                          <template #message="{ key, message }">
                            <portal to="voiceField" v-if="!!message">
                              {{ message }}
                            </portal>
                          </template>
                        </SelectField>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
  
                <v-expand-transition>
                  <v-col cols="12" v-if="sayOption">
                    <v-row no-gutters>
                      <v-col
                        class="d-flex flex-column justify-center pr-sm-11 pb-4 pb-sm-10"
                        cols="12" sm="5" md="4" lg="3"
                      >
                        <span class="font-normal nameField--text text-sm-right">File</span>
                        <portal-target
                          class="field-message font-normal error--text text-sm-right"
                          name="fileField"
                        ></portal-target>
                      </v-col>
        
                      <v-col class="pb-10 d-flex" cols="12" sm="7" md="8" lg="9">
                        <AutocompleteField
                          class="field-container mr-7"
                          v-model="media"
                          :items="medias"
                          item-text="name"
                          item-value="id"
                          :rules="rules"
                          checkBg
                        >
                          <template #message="{ key, message }">
                            <portal to="fileField" v-if="!!message">
                              {{ message }}
                            </portal>
                          </template>
                        </AutocompleteField>
  
                        <ActionOutlinedButton height="46" min-width="52" class="pr-0 pl-0" @click="openChooseFile">
                          <v-icon>$uploadIcon</v-icon>
                        </ActionOutlinedButton>
  
                        <input
                          ref="uploader"
                          class="d-none"
                          type="file"
                          accept=".mp3"
                          @change="saveMedia"
                        >
                      </v-col>
                    </v-row>
                  </v-col>
                </v-expand-transition>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        
        <v-row class="d-block d-md-flex align-center" no-gutters>
          <v-col cols="12" md="8" lg="10" offset="0" offset-md="4" offset-lg="2">
            <v-row no-gutters>
              <v-col class="pr-sm-11 pb-4 pb-sm-0" cols="12" sm="5" md="4" lg="3"></v-col>
              
              <v-col class="pb-10" cols="12" sm="7" md="8" lg="9">
                <NumbersPicker
                  :menu="menu"
                  @updateMenu="updateMenu"
                />
              </v-col>
              
              <v-col cols="12" sm="7" md="8" lg="9" offset="0" offset-sm="5" offset-md="4" offset-lg="3" class="mt-2 mt-sm-8">
                <CancelButton @click="closeAction" class="mr-8 mr-sm-10">Cancel</CancelButton>
                
                <ActionButton @click="submitForm" :loading="loadingAction">{{ ivrSlug ? 'Update' : 'Create' }}</ActionButton>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import ActionButton from '@/components/buttons/ActionButton'
import CancelButton from '@/components/buttons/CancelButton'
import ToggleButton from '@/components/buttons/ToggleButton'
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import TextDescription from '@/components/description/TextDescription'
import FormField from '@/components/inputs/FormField'
import FormTextarea from '@/components/inputs/FormTextarea'
import InputNumberControl from '@/components/inputs/InputNumberControl'
import FileInput from '@/components/inputs/FileInput'
import AutocompleteField from '@/components/select/AutocompleteField'
import SelectField from '@/components/select/SelectField'
import PrimarySlider from '@/components/controls/PrimarySlider'
import NumbersPicker  from '@/components/pickers/NumbersPicker'
import AudioPlayer from '@/components/reporting/AudioPlayer'

import { getDetailIvr, createIvr, editIvr, getLanguages } from '@/api/ivr-routes'
import { getAllMedias, createMedia } from '@/api/media-routes'

export default {
  name: 'DetailIvr',
  components: { ActionButton, CancelButton, ToggleButton, ActionOutlinedButton, TextDescription, FormField, FormTextarea, InputNumberControl, FileInput, AutocompleteField, SelectField, PrimarySlider, NumbersPicker, AudioPlayer },
  data: () => ({
    validateForm: true,
    formData: {
      name: null,
      'time_limit': 25,
      tries: 1,
    },
    formDataSay: {
      description: '',
      'language_id': null,
      voice: null,
    },
    media: null,
    file: null,
    fileLink: null,
    menu: [false, false, false, false, false, false, false, false, false, false],
    sayOption: 0,
    sayOptions: [
      { name: 'Say', id: 0 },
      { name: 'Play', id: 1 }
    ],
    medias: [],
    languages: [],
    voices: [],
    rules: [],
    description: [
      {
        title: 'Setup Your IVR',
        text: []
      },
    ],
    ivrSlug: null,
    loadingDetail: false,
    loadingAction: false,
    audioFile: null,
  }),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width < 600
    },
  },
  mounted() {
    this.loadLanguages()
    this.loadMedias()
    if (this.$route.params.slug) {
      this.ivrSlug = this.$route.params.slug
      this.loadDetailIvr()
    }
  },
  methods: {
    async loadDetailIvr() {
      this.loadingDetail = true

      const { success, payload, message } = await getDetailIvr(this.ivrSlug)

      if (success) {
        this.formData = {
          name: payload.name,
          'time_limit': payload['time_limit'],
          tries: payload.tries,
        }
        this.menu = payload.menu
        
        if (payload['language_id']) {
          this.formDataSay = {
            description: payload.description,
            'language_id': payload['language_id'],
            voice: payload.voice,
          }
        } else {
          this.sayOption = 1
          this.media = payload['media_id']
        }
        this.changeLanguage(payload['language_id'])
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingDetail = false
    },
    async loadMedias() {
      const { success, payload, message } = await getAllMedias()

      if (success) {
        this.medias = payload
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    async loadLanguages() {
      const { success, payload, message } = await getLanguages()

      if (success) {
        this.languages = payload
        
        if (this.formDataSay['language_id']) {
          this.changeLanguage(this.formDataSay['language_id'])
        }
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    changeLanguage(languageId) {
      const currentLanguage = this.languages.find(language => language.id === languageId)
      this.voices = []
      
      if (!currentLanguage) {
        return
      }
      
      if (!!currentLanguage.man) {
        this.voices.push({
          name: 'Man',
          id: 'man'
        })
      }
      if (!!currentLanguage.woman) {
        this.voices.push({
          name: 'Woman',
          id: 'woman'
        })
      }
      this.formDataSay.voice = !!this.voices.length ? this.voices[0].id : null
    },
    updateMenu(menu) {
      this.menu = menu.slice()
    },
    async submitForm() {
      await this.validationForm()
      if (this.$refs.form.validate()) {
        let formData = Object.assign({}, this.formData, { menu: JSON.stringify(this.menu) })
        
        if (!this.sayOption) {
          formData = Object.assign({}, formData, this.formDataSay, { 'media_id': null })
        } else {
          formData = Object.assign({}, formData, { 'media_id': this.media })
        }

        if (!!this.ivrSlug) {
          this.updateIvr(formData)
        } else {
          this.createIvr(formData)
        }
      }
    },
    validationForm() {
      this.rules = [
        v => !!v || 'This field is required'
      ]
    },
    async createIvr(formData) {
      this.loadingAction = true

      const { success, message } = await createIvr(formData)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    async updateIvr(formData) {
      this.loadingAction = true

      const { success, message } = await editIvr({
        ivrId: this.ivrSlug,
        formData
      })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })

        this.closeAction()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    openChooseFile() {
      this.$refs['uploader'].click()
    },
    async saveMedia(file) {
      if (!file) {
        return
      }
      
      if (file.target.files[0].size > 2048000) {
        this.$notify({ type: 'error-bg', text: 'File size should be less than 2 MB!' })
        return
      }
      
      let formData = {}
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }

      formData = new FormData()
      formData.append('file', file.target.files[0])

      const { success, payload, message } = await createMedia({ formData, config })

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.medias.push(payload)
        this.media = payload.id
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
    },
    closeAction() {
      this.$router.push({ name: 'ManageIvr'})
    },
  },
}
</script>
