<template>
  <div class="width--174">
    <v-row justify="center">
      <v-col
        cols="4"
        v-for="(number, index) of numbers"
        :key="index"
      >
        <SwitchButton
          :value="!!number"
          class="borderColorCustom"
          isPicker
          @openDialog="openDialog(number, index)"
        ><span class="subtitle-1">{{ getIndex(index) }}</span></SwitchButton>
      </v-col>
    </v-row>
    
    <DialogChangeRoutingPlan
      v-model="dialog"
      :currentRoutingPlan="currentRoutingPlan"
      @updateRoutingPlan="updateRoutingPlan"
    />
  </div>
</template>

<script>
import SwitchButton from '@/components/controls/SwitchButton'
import DialogChangeRoutingPlan from '@/components/dialog/DialogChangeRoutingPlan'

export default {
  name: 'NumbersPicker',
  inheritAttrs: false,
  components: { SwitchButton, DialogChangeRoutingPlan },
  props: {
    menu: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    dialog: false,
    currentIndex: null,
    currentRoutingPlan: null,
    numbers: [false, false, false, false, false, false, false, false, false, false]
  }),
  computed: {
  
  },
  methods: {
    openDialog(value, index) {
      this.currentIndex = index
      this.currentRoutingPlan = value
      this.dialog = true
    },
    getIndex(index) {
      if (index + 1 < 10) {
        return index + 1
      } else {
        return 0
      }
    },
    updateRoutingPlan(planId) {
      this.numbers[this.currentIndex] = planId ? planId : false
      this.$emit('updateMenu', [].concat(this.numbers[9], this.numbers.slice(0, 9)))
      this.dialog = false
    },
  },
  watch: {
    menu: {
      deep: true,
      handler(menu) {
        this.numbers = [].concat(menu.slice(1), menu[0])
      }
    },
    dialog: {
      handler(value) {
        if (!value) {
          this.currentIndex = null
          this.currentRoutingPlan = null
        }
      }
    }
  },
}
</script>
